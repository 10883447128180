import React from "react";
import Logo from "../logo/Logo";
import FooterLinkItem from "../Footer/FooterLinkItem.jsx";
import { PortableText } from "@portabletext/react";

const Footer = ({ data }) => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <Logo image={data?.logo} />
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  {data?.contact.map((item, index) =>
                    item.inputType === "link" ? (
                      <p key={index}>
                        <a href={item.link}>{item.value}</a>
                      </p>
                    ) : (
                      <p key={index}>{item.value}</p>
                    )
                  )}
                </div>
                <div className="footer-social-inline">
                  {data?.social.map((item, index) => (
                    <a href={item?.url} key={index} aria-label="link">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.icon }}
                        className="flex justify-center"
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {data?.menu &&
            data.menu.map((single, key) => {
              return (
                <div
                  key={key}
                  className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6"
                >
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}
        </div>

        <div className="row">
          <div className="col">
            <div className="copyright">
              {data?._rawCc && <PortableText value={data?._rawCc} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
