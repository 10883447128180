import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Logo = ({ image }) => {
  return (
    <div className="header-logo">
      <Link to={"/"}>
        <img
          className="dark-logo"
          src={`${image?.asset.url}?auto=format&w=150`}
          alt={image?.alt}
          height={100}
        />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  image: PropTypes.object,
};

export default Logo;
